import { Affiliate } from './AffiliateApi';
import { createClient } from './client';
import { MoneyMinor } from './shared';

export type Merchant = {
  id: string;
  name: string;
  company: Company;
  createdAt: string;
  updatedAt: string;
};

export type Company = {
  name: string;
  address: string;
  organizationNumber: string;
  vatRegistrationNumber: string;
}

export type BackofficeCreateEntityPermission = {
  createOfferAllowed: boolean;
  createUserAllowed: boolean;
  createdAt: string;
  updatedAt: string;
}

export type MerchantConfig = {
  sendNotification: boolean;
  loanApplicationsEnabled: boolean;
  paymentTimeoutDuration: string;
  minAgeYears: number;
  autogiroCustomerReference?: string;
  antiFraud: AntiFraud;
  escrow: Escrow;
  offer: Offer;
  serviceFee: ServiceFee;
  currencies: MerchantCurrency[];
  backofficeCreateEntityPermission?: BackofficeCreateEntityPermission;
}

export type MerchantCurrency = {
  offerMinPrice: MoneyMinor;
  offerMaxPrice: MoneyMinor;
  serviceFeeMinPrice?: MoneyMinor;
  serviceFeeMaxPrice?: MoneyMinor;
}

export type AntiFraud = {
  maxNumberOfWeeklyPayouts: number;
  maxWeeklyPayout: MoneyMinor;
  minimumBankIdAge: number;
};

export type Escrow = {
  firstReminderDuration: string;
  secondReminderDuration: string;
  timeoutDuration: string;
  timeoutAction: string;
};

export type Offer = {
  bidEnabled: boolean;
};

export type ServiceFee = {
  campaignMode: boolean;
};

export type ShippingMethod = {
  merchantId: string;
  shippingMethod: string;
  shipmentRejectionPeriod: string;
  listOrder: number;
  createdAt: string;
  updatedAt: string;
};

export type PaymentMethod = {
  merchantId: string;
  paymentMethod: string;
  minPrice: MoneyMinor;
  maxPrice: MoneyMinor;
  fee: MoneyMinor;
  listOrder: number;
  createdAt: string;
  updatedAt: string;
  allowUnauthenticatedBuyers: boolean;
};

export type PaymentMethodPayload = {
  minPrice: number;
  maxPrice: number;
  fee: number;
  listOrder: number;
};

export type PayoutMethod = {
  merchantId: string;
  payoutMethod: string;
  enabled: boolean;
  minAmount: MoneyMinor;
  maxAmount: MoneyMinor;
  fee: MoneyMinor;
  listOrder: number;
  minAgeYears?: number;
  createdAt: string;
  updatedAt: string;
};

export type GoodwillPayoutMethod = {
  merchantId: string;
  payoutMethod: string;
  enabled: boolean;
  cvAccountId: string;
  maxAmount: MoneyMinor;
  totalAmountPeriod: string;
  totalAmountForPeriod: MoneyMinor;
  totalCountPeriod: string;
  totalCountForPeriod: number;
  listOrder: number;
  createdAt: string;
  updatedAt: string;
};

export type PayoutMethodPayload = {
  minPrice: number;
  maxPrice: number;
  fee: number;
  listOrder: number;
};

export type Config = {
  notification: string;
  email: boolean;
  sms: boolean;
};

export class MerchantApi {
  client;

  constructor() {
    this.client = createClient();
  }

  async getMerchant(merchantId: string): Promise<Merchant> {
    const resp = await this.client.get(
      `/v1/back-office/merchants/${merchantId}`,
    );
    return resp.data;
  }

  async getMerchantConfig(merchantId: string): Promise<MerchantConfig> {
    const resp = await this.client.get(
      `/v1/back-office/merchants/${merchantId}/config`,
    );
    return resp.data;
  }

  async getMerchantShippingMethods(
    merchantId: string
  ): Promise<{ shippingMethods: ShippingMethod[] }> {
    const resp = await this.client.get(
      `/v1/back-office/merchants/${merchantId}/shipping-methods`,
    );
    return resp.data;
  }

  async getMerchantAffiliates(
    merchantId: string
  ): Promise<{ affiliates: Affiliate[] }> {
    const resp = await this.client.get(
      `/v1/back-office/merchants/${merchantId}/affiliates`,
    );
    return resp.data;
  }

  async getMerchantPaymentMethods(
    merchantId: string,
    currency?: string
  ): Promise<{ paymentMethods: PaymentMethod[] }> {
    const resp = await this.client.get(
      `/v1/back-office/merchants/${merchantId}/payment-methods?currency=${currency}`,
    );
    return resp.data;
  }

  async updatePaymentMethod({
    merchantId,
    paymentMethod,
    data,
    currency
  }: {
    merchantId: string;
    paymentMethod: string;
    data: PaymentMethodPayload;
    currency: string
  }): Promise<PaymentMethod> {
    const resp = await this.client.put(
      `/v1/back-office/merchants/${merchantId}/payment-methods/${paymentMethod}?currency=${currency}`,
      data,
    );
    return resp.data;
  }

  async getMerchantPayoutMethods(
    merchantId: string,
    currency?: string
  ): Promise<{ payoutMethods: PayoutMethod[] }> {
    const resp = await this.client.get(
      `/v1/back-office/merchants/${merchantId}/payout-methods?currency=${currency}`,
    );
    return resp.data;
  }

  async getMerchantGoodwillPayoutMethods(
    merchantId: string,
    currency?: string
  ): Promise<{ payoutMethods: GoodwillPayoutMethod[] }> {
    const resp = await this.client.get(
      `/v1/back-office/merchants/${merchantId}/goodwill-payout-methods?currency=${currency}`,
    );
    return resp.data;
  }

  async getGoodwillPayoutBookkeepingBalance(merchantId: string, method: string, currency: string): Promise<{ balance: MoneyMinor }> {
    const resp = await this.client.get(
      `/v1/back-office/merchants/${merchantId}/goodwill-payout-methods/${method}/balance?currency=${currency}`,
    );
    return resp.data;
  }

  async updatePayoutMethod({
    merchantId,
    payoutMethod,
    data,
    currency
  }: {
    merchantId: string;
    payoutMethod: string;
    data: PayoutMethodPayload;
    currency: string;
  }): Promise<PayoutMethod> {
    const resp = await this.client.put(
      `/v1/back-office/merchants/${merchantId}/payout-methods/${payoutMethod}?currency=${currency}`,
      data,
    );
    return resp.data;
  }

  async getNotificationConfig(
    merchantId: string,
  ): Promise<{ configs: Config[] }> {
    const resp = await this.client.get(
      `/v1/back-office/merchants/${merchantId}/notifications-config`,
    );
    return resp.data;
  }

  async updateNotificationConfig({
    merchantId,
    notification,
    payload,
  }: {
    merchantId: string;
    notification: string;
    payload: { [key: string]: boolean };
  }): Promise<{ configs: Config[] }> {
    const resp = await this.client.put(
      `/v1/back-office/merchants/${merchantId}/notifications-config/${notification}`,
      payload,
    );
    return resp.data;
  }
}
