import { useState } from 'react';
import {
  List,
  Grid,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Chip,
  Typography
} from '@mui/material';
import { GoodwillPayoutMethod, MerchantApi } from '../../../api/MerchantApi';
import formatDate from '../../../utils/format-date';
import formatMoney from '../../../utils/format-money';
import formatDuration from '../../../utils/format-duration';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useAuthContext } from '../../../context/AuthContext';
import { useNotificationContext } from '../../../context/NotificationContext';
import { useParams } from 'react-router';
import { MoneyMinor } from '../../../api/shared';
import Modal from '../../../components/Modal';


const MerchantGoodwillPayoutMethods = ({
  payoutMethods,
  merchantCurrency
}: {
    payoutMethods: GoodwillPayoutMethod[];
    merchantCurrency: string;
  }) => {
  const { user: { isSuperAdmin } } = useAuthContext();
  const [balanceModal, setBalanceModal] = useState<MoneyMinor>();
  const merchantApi = new MerchantApi();
  const { setNotification } = useNotificationContext();
  const { merchantId } = useParams<{ merchantId: string }>();

  const GoodwillPayoutMethodRow = ({
    payoutMethod
  }: {
    payoutMethod: GoodwillPayoutMethod;
  }) => {
    const checkBalance = async () => {
      try {
        const { balance } = await merchantApi.getGoodwillPayoutBookkeepingBalance(merchantId,payoutMethod.payoutMethod, merchantCurrency);
        setBalanceModal(balance)
      } catch (e) {
        setNotification({
          severity: 'error',
          message: 'Something went wrong. please try again or contact support',
        });
      }
      
    }

    return <Card
      sx={{ p: 2, mb: 2, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <b style={{ textDecoration: 'underline', fontSize: 14 }}>
            {payoutMethod.payoutMethod}
          </b>
          {!payoutMethod.enabled && <Chip label="Disabled" variant="outlined" color="error" size="small" sx={{ ml: 1 }} />}
        </div>
      </div>
      <Grid container spacing={0.5}>
        <Grid item xs={6} md={3} lg={2}>
          <b>List order</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{payoutMethod.listOrder}</span>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>CV account ID</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <div>
            <span>{payoutMethod.cvAccountId}</span>
            {isSuperAdmin && <IconButton sx={{ padding: '0px 20px' }} onClick={checkBalance}>
              <AccountBalanceIcon sx={{ fontSize: '1.25rem' }} />
            </IconButton>}
          </div>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Max amount</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{formatMoney(payoutMethod.maxAmount)}</span>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Total amount</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{formatMoney(payoutMethod.totalAmountForPeriod)} for {formatDuration(payoutMethod.totalAmountPeriod)}</span>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Total count</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{payoutMethod.totalCountForPeriod} for {formatDuration(payoutMethod.totalCountPeriod)}</span>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Created at</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{formatDate(payoutMethod.createdAt)}</span>
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <b>Updated at</b>
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <span>{formatDate(payoutMethod.updatedAt)}</span>
        </Grid>
      </Grid>
    </Card>
  };
  
  
  return (
    <>
      <Card>
        <CardHeader
          title="Goodwill payout methods:"
          titleTypographyProps={{ fontSize: 16 }}
        />
        <CardContent>
          {payoutMethods.map((method, i) => (
            <List key={method.payoutMethod} component="div" disablePadding>
              <GoodwillPayoutMethodRow payoutMethod={method} />
            </List>
          ))}
        </CardContent>
      </Card>
      {balanceModal && <Modal onClose={() => setBalanceModal(undefined)}>
        <Typography
          component="h2"
          mb={0}
          sx={{ fontSize: '24px', lineHeight: '36px', fontWeight: '700' }}
        >
          Balance: {formatMoney(balanceModal)}
        </Typography>
      </Modal>}
    </>
  );
};

export default MerchantGoodwillPayoutMethods;
