import { IconButton, InputBase, Paper } from '@mui/material';
import { Search } from '@mui/icons-material';
import React from 'react';

type SearchBarProps = {
  onSearch?: (query: string) => void;
};

const SearchBar = (props: SearchBarProps) => {
  const [query, setQuery] = React.useState('');
  const onSubmit = (e: any) => {
    e.preventDefault();
    if (props.onSearch) {
      props.onSearch(query);
    }
  };

  return (
    <Paper
      component="form"
      onSubmit={onSubmit}
      sx={{
        display: 'flex',
        p: '0px 16px',
        margin: '0px 16px 0px 0px',
        maxWidth: 350,
        borderRadius: '50px',
      }}
    >
      <InputBase
        placeholder="Search"
        fullWidth
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <IconButton
        color="secondary"
        type="submit"
        aria-label="search"
        size="large"
      >
        <Search />
      </IconButton>
    </Paper>
  );
};

export default SearchBar;
